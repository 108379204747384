<!--  -->
<template>
  <div class="capital card">
    <div class="btt">
      <div class="btts"><span></span>资金明细</div>
    </div>
    <div class="bj">
      <div class="money">
        <div>账户余额</div>
        <p><span>¥</span>{{Prolist.ext_cash}}</p>
      </div>
      <div class="money">
        <div>可用余额</div>
        <p><span>¥</span>{{Prolist.ext_cash}}</p>
      </div>
      <div class="icon">
        <div class="capital-img">
          <div>冻结余额<img src="../../assets/member/dj.png" alt="" v-if="false" /></div>
          <p><span>¥</span>{{Prolist.free_cash}}</p>
        </div>
        <div class="block" v-if="false">
          <p>账号租赁：¥0.00</p>
          <p>错误赔付：¥0.00</p>
          <p>保证金：¥0.00</p>
        </div>
      </div>
      <div class="btn" @click="$router.push({path: `recharge/${Prolist.phonenum}`})">
        <img src="../../assets/member/cz-zjmx.png" alt="" />
        充值
      </div>
    </div>
    <div class="inputs">
      <div class="common-input">
        <span class="span2">资金流向：</span>
        <el-select v-model="value" clearable placeholder="全部" size="mini" @change="checkFlow">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
        <div class="common-input">
        <span class="span1">类型：</span>
        <el-select v-model="values" clearable placeholder="出租" size="mini" @change="checkFlow">
          <el-option
            v-for="item in options1"
            :key="item.values"
            :label="item.label"
            :value="item.values"
          >
          </el-option>
        </el-select>
      </div>
      <div class="picker">
        <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
             @change="getTime"
             value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>
      <el-input
        size="mini"
        placeholder="订单号"
        suffix-icon="el-icon-search"
		@blur="checkFlow"
        v-model.trim="input4">
      </el-input>
    </div>
    <div class="tables">
        <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            border
           >
            <el-table-column
            prop="created_at"
            label="时间"
            width="180">
            </el-table-column>
            <el-table-column
            prop="type"
            label="类型"
            width="80">
            </el-table-column>
            <el-table-column
            prop="fina_desc"
            label="明细"
             width="100">
            </el-table-column>
            <el-table-column
            prop="ordernum"
            label="相关交易订单"
            >
            </el-table-column>
            <el-table-column
            prop="amount_str"
            label="变动金额"
             width="100">
             <template slot-scope="scope">
              <span :class="scope.row.amount_str.indexOf('+') != -1? 'color1': 'color2'">{{ scope.row.amount_str }}</span>
            </template>
            </el-table-column>
            <el-table-column
            prop="left_cash"
            label="变动后可用余额"
            width="140">
             <template slot-scope="scope">
              <span style="color:#000;">{{ scope.row.left_cash }}</span>
            </template>
            </el-table-column>
        </el-table>
    </div>
      <!-- 分页器 -->
      <div class="paging">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="5"
            layout="prev, pager, next,total, jumper"
            :total="count"
            background
            ></el-pagination>
      </div>
  </div>
</template>
 
<script>
export default {
  name: "capital",
  data() {
    return {
      start: '',
      end:"",
      page: 1, //页码
      pageSize: 5, // 每页条数
      currentPage: 1, //分页器当前页
      count : 0, //总条数
      options1:[
		{
			values: "",
			label: "全部"
		},
         { 
           values: "1",
           label: "充值"
          },
           { 
           values: "2",
           label: "出租" 
          },
           { 
           values: "3",
           label: "其他" 
          },
      ],
      options: [
          { 
           value: "",
           label: "全部" 
          },
          {
           value: "1",
           label: "收入" 
          },
          { 
           value: "2",
           label: "支出" 
          }
        ],
        values:'',
        value: '',
        value1:'',
        input4:'',
        tableData: [],
        Prolist:{}
    };
  },
  created() {
    this.gitList()
    this.getProfile()
  },
  mounted() {
  },
  methods: {
    gitList() {
		const that = this
		that.$get('api/recharge/list', { year:that.start, month:that.end,opera:that.value,type:that.values,order_no:that.input4, page_size:that.pageSize,page:that.page }).then(res => {                     
			if (res.code == 0) {
				this.tableData = res.ret
				if(that.page==1){
					if(this.tableData.length>0){
						this.count = this.tableData[0].count
					}else{
						this.count = 0
					}					
				}
			}else{
				this.$message.error(res.message)
			}
		})
	},
	checkFlow(){
		this.gitList()
	},
    getTime() {
		this.start = this.value1[0]
		this.end = this.value1[1]
		this.page = 1
		this.gitList()
    },
      //分页器设置
    handleSizeChange(val) {
      this.page = val
      this.gitList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.gitList()
    },
    // 可用余额
    getProfile () {
		this.$get('api/getinfo', {}).then(res => {
        if (res.code === 0) {
          this.Prolist = res.ret
        }
      })
    },
  }
};
</script>
 
<style lang='scss' scoped >
.capital {
  width: 968px;
  float: right;
  .btt {
    width: 100%;
    height: 65px;
    padding: 25px 35px 0;
    .btts {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      span {
        display: inline-block;
        width: 4px;
        height: 16px;
        background: #3c7efe;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }
  .bj {
    margin: 0 auto;
    width: 894px;
    height: 141px;
    border-radius: 4px;
    background: url("../../assets/member/bj-zjmx.png") no-repeat center;
    background-size: 100%;
    display: flex;
    align-items: center;
    position: relative;
    .money {
      width: 184px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #fffefe;
      border-right: 2px dashed #fffefe;
      padding: 20px 0 20px 35px;
      p {
        font-size: 30px;
        span {
          font-size: 14px;
          margin-right: 10px;
        }
      }
    }
    .icon {
      display: flex;
      &:hover .block {
        display: block;
      }
      .capital-img {
        width: 131px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fffefe;
        padding: 20px 0 20px 35px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin: 0 0 3px 5px;
        }
        p {
          font-size: 30px;
          span {
            font-size: 14px;
            margin-right: 10px;
          }
        }
      }
      .block {
        width: 157px;
        height: 87px;
        background: #ffffff;
        border-radius: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #83899d;
        padding-top: 15px;
        position: relative;
        display: none;
        p {
          text-align: center;
        }
        &::after {
          content: "";
          position: absolute;
          top: 19px;
          left: -7px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: 7px solid #ebeef5;
          z-index: 10000;
        }
        &::before {
          content: "";
          position: absolute;
          top: 20px;
          left: -6px;
          width: 0;
          height: 0;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          border-right: 6px solid #fff;
          z-index: 10001;
        }
      }
    }
    .btn {
      width: 140px;
      height: 49px;
      background: #ffffff;
      border-radius: 24px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #3c7efe;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 60px;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      img {
        width: 20px;
        height: 20px;
        margin-right: 3px;
      }
    }
  }
  .inputs{
      margin: 32px 35px 21px;
      display: flex;
      align-items: center;
    .common-input{
        display: flex;
        align-items: center;
        margin-right: 30px;
        .span1{
            width: 80px;
        }
        .span2{
            width: 120px;
        }
    }
    .picker{
        width: 360px;
        margin-right: 30px;
    }
  }
  .tables{
      margin: 0 35px;
  }
}

.color1{
  color:#FC3939 ;
}
.color2{
  color:#24D863 ;
}
//
.card {
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10px;
}
//分页器设置
.paging {
  padding: 30px 10px;
}
.el-pagination.is-background .el-pager li:not(.disabled) {
  border-radius: 15px;
  margin: 0px 8px;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #3c7efe;
  color: #fff;
}
.el-pagination.is-background .el-pager li.active {
  color: #fff;
  cursor: default;
}
.el-pagination.is-background .el-pager li:hover {
  color: #3c7efe;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #3c7efe;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: #3c7efe;
  color: #fff;
}
.el-pagination.is-background .el-pagination__total {
  margin-left: 25%;
}
</style>